import { LoginState } from "./loginTypes";

export const initialState: LoginState = {
  name: "",
  token: "",
  role: 0,
  pin: "",
  sagra_id: 1,
  device_id: "",
  confirmed: false,
  loading: false,
  status: "",
};
