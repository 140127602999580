import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../redux/store";
import { HttpRequest } from "../base/HttpModels";
import { BaseRequest } from "../base/BaseRequest";
import axios from "axios";
import { REQUEST_URL } from "../base/Constants";
import { USER_DELETE, USER_LIST, USER_SET_ACCEPTED } from "./usersActions";
import { AcceptRequest, DeleteRequest, User } from "./usersTypes";
import { initialState } from "./usersInitialState";
import { logMessage } from "../utils/logger";
import { Logout } from "../login/loginSlice";

export const userDelete = createAsyncThunk(
  USER_DELETE,
  async (request: HttpRequest<DeleteRequest>) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[userDelete] " + JSON.stringify(response));
      if (response !== null && response.data.errors.length === 0) {
        return request;
      }
      return null;
    } catch (error) {
      logMessage("[userDelete] " + JSON.stringify(error));
      return null;
    }
  }
);

export const userSetAccepted = createAsyncThunk(
  USER_SET_ACCEPTED,
  async (request: HttpRequest<AcceptRequest>) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[userSetAccepted] " + JSON.stringify(response));
      let confirmAccepted =
        request.parameters.is_accepted && response.data.errors.length === 0;
      let confirmDenied =
        !request.parameters.is_accepted && response.data.errors.length === 0;

      let status = "pending";
      if (confirmAccepted) {
        status = "accepted";
      }
      if (confirmDenied) {
        status = "denied";
      }
      return {
        id: request.parameters.user_id,
        status: status,
      };
    } catch (error) {
      logMessage("[userSetAccepted] " + JSON.stringify(error));
      return null;
    }
  }
);

export const getUserList = createAsyncThunk(
  USER_LIST,
  async (request: HttpRequest<BaseRequest>, dispatch) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage(">>> [getUserList] " + JSON.stringify(response));
      if(response.data.errors.length > 0 && response.data.errors[0] === "invalid_role") {
        console.log("Invalid role, logging out");
        dispatch.dispatch(Logout())
        localStorage.removeItem("persist:root-2");
        return null;
      }
      return response.data.results as Array<User>;
    } catch (error) {
      logMessage(">>> [getUserList] " + JSON.stringify(error));
      return null;
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userDelete.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(userDelete.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload === null) {
          state.error = "Error while deleting user";
        } else {
          state.users = state.users.filter(
            (user) => user.id !== action.payload?.parameters.user_id
          );
        }
      })
      .addCase(getUserList.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload === null) {
          state.error = "Error while retrieving users";
        } else {
          state.users = action.payload;
        }
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.loading = false;
        state.error = "Users request rejected";
      })
      .addCase(userSetAccepted.pending, (state, action) => {
        state.error = undefined;
      })
      .addCase(userSetAccepted.fulfilled, (state, action) => {
        if (action.payload === null) {
          state.error = "Error while accepting user";
        } else {
          state.users = state.users.map((user) => {
            if (action.payload !== null && user.id === action.payload.id) {
              return {
                ...user,
                status: action.payload.status,
              };
            }
            return user;
          });
        }
      })
      .addCase(userSetAccepted.rejected, (state, action) => {
        state.error = "Accept user request rejected";
      });
  },
});

export const {} = usersSlice.actions;

export const selectUsers = (state: RootState) => state.userState;

export default usersSlice.reducer;
