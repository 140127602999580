import { OrdersState } from "./ordersTypes";

export const initialState: OrdersState = {
  onlyToBePrinted: true,
  orders: [],
  fullOrders: [],
  loading: false,
  error: undefined,
  orderDetail: 0,
};
