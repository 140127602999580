import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../redux/store";
import { initialState } from "./menuInitialState";
import { IDishGroup, IUpdateDishRequest } from "./menuTypes";
import { DISH_UPDATE, MENU_LIST } from "./menuActions";
import { HttpRequest } from "../base/HttpModels";
import { BaseRequest } from "../base/BaseRequest";
import axios from "axios";
import { REQUEST_URL } from "../base/Constants";
import { logMessage } from "../utils/logger";
import { Logout } from "../login/loginSlice";

export const updateDish = createAsyncThunk(
  DISH_UPDATE,
  async (request: HttpRequest<IUpdateDishRequest>, dispatch) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[updateDish] " + response);
      const menuRequest: HttpRequest<BaseRequest> = {
        action: MENU_LIST,
        parameters: {
          user_token: request.parameters.user_token,
        },
      };
      dispatch.dispatch(getMenuList(menuRequest));
    } catch (error) {
      logMessage("[updateDish] " + error);
      return null;
    }
  }
);

export const getMenuList = createAsyncThunk(
  MENU_LIST,
  async (request: HttpRequest<BaseRequest>, dispatch) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage(">>> [getMenuList] " + JSON.stringify(response));
      if(response.data.errors.length > 0 && response.data.errors[0] === "invalid_role") {
        console.log("Invalid role, logging out");
        dispatch.dispatch(Logout())
        localStorage.removeItem("persist:root-2");
        return null;
      }
      return response.data.results as Array<IDishGroup>;
    } catch (error) {
      return null;
    }
  }
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDish.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(updateDish.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload === null) {
          state.error = "Error while retrieving menu";
        }
      })
      .addCase(getMenuList.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(getMenuList.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload === null) {
          state.error = "Error while retrieving menu";
        } else {
          state.menu = action.payload;
        }
      })
      .addCase(getMenuList.rejected, (state, action) => {
        state.loading = false;
        state.error = "Menu request rejected";
      });
  },
});

export const {} = menuSlice.actions;

export const selectMenu = (state: RootState) => state.menuState;

export default menuSlice.reducer;
