import { combineReducers, configureStore } from "@reduxjs/toolkit";
import menuSlice from "../menu/menuSlice";
import ordersSlice from "../order_history/ordersSlice";
import loginSlice from "../login/loginSlice";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import usersSlice from "../users/usersSlice";
import takeOrderSlice from "../take_order/orderSlice";
import messageSlice from "../message/messageSlice";
import debugSlice from "../debug/debugSlice";
import { logMessage } from "../utils/logger";

const logger = (store: any) => (next: any) => (action: any) => {
  logMessage("dispatching " + JSON.stringify(action));
  let result = next(action);
  console.log("next state " + JSON.stringify(store.getState()));
  return result;
};

const persistConfig = {
  key: "root-2",
  storage,
};

const rootReducer = combineReducers({
  menuState: menuSlice,
  ordersState: ordersSlice,
  loginState: loginSlice,
  userState: usersSlice,
  takeOrderState: takeOrderSlice,
  messageState: messageSlice,
  debugState: debugSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
