import { Container, Box } from "@mui/material";
import { Typography } from "@mui/material"; // Add this line
import { logs } from "../utils/logger";

export const DebugComponent = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        {logs.map((item) => (
          <Typography key={item} variant="body1" align="left">
            {item}
          </Typography>
        ))}
      </Box>
    </Container>
  );
};
