import { Typography } from "@mui/material";

interface TitleProps {
  title: string;
}

export const TitleComponent = (title: TitleProps) => {
  return (
    <Typography component="h1" variant="h5">
      Oratorio Bevera Tavola Calda 2024
    </Typography>
  );
};
