import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./orderInitialState";
import { IOrderLine, PutOrderRequest, PutOrderResponse } from "./orderTypes";
import { RootState } from "../redux/store";
import { ORDER_PUT } from "./orderActions";
import { HttpRequest } from "../base/HttpModels";
import axios from "axios";
import { REQUEST_URL } from "../base/Constants";
import { setMessage } from "../message/messageSlice";
import { logMessage } from "../utils/logger";
import { changeOrderStatus } from "../order_history/ordersSlice";
import { IChangeOrderStatusRequest } from "../order_history/ordersTypes";
import { ORDER_CHANGE_STATUS } from "../order_history/orderHistoryActions";

export const putOrder = createAsyncThunk(
  ORDER_PUT,
  async (request: HttpRequest<PutOrderRequest>, dispatch) => {
    try {
      const token = request.parameters.user_token;
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[putOrder] " + JSON.stringify(response));
      const result = response.data.results[0] as PutOrderResponse;
      if (response.data.errors.length > 0) {
        dispatch.dispatch(
          setMessage(
            "Errore durante la creazione dell'ordine\n" +
              response.data.errors[0]
          )
        );
        return null;
      } else {
        dispatch.dispatch(
          setMessage("Ordine #" + result.order_id + " creato correttamente")
        );
        const request: HttpRequest<IChangeOrderStatusRequest> = {
          action: ORDER_CHANGE_STATUS,
          parameters: {
            order_id: result.order_id,
            order_status_id: 2, // PAID
            user_token: token,
          },
        };
        dispatch.dispatch(changeOrderStatus(request));
      }
      return result;
    } catch (error) {
      dispatch.dispatch(
        setMessage("Errore durante la creazione dell'ordine\n" + error)
      );
      logMessage("[putOrder] " + JSON.stringify(error));
      return null;
    }
  }
);

export const takeOrderSlice = createSlice({
  name: "takeOrder",
  initialState,
  reducers: {
    setTable: (state, action: PayloadAction<string>) => {
      state.table_name = action.payload;
    },
    setSector: (state, action: PayloadAction<string>) => {
      state.sector = action.payload
    },
    editOrder: (state, action: PayloadAction<IOrderLine>) => {
      state.order_lines = state.order_lines
        .filter((order) => order.dish_id !== action.payload.dish_id)
        .concat(action.payload)
        .filter((order) => order.quantity > 0);
    },
    reset: (state) => {
      state.table_name = "";
      state.order_lines = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(putOrder.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(putOrder.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload === null) {
          state.error = "Errore durante la creazione dell'ordine";
        } else {
          state.error = undefined;
          state.table_name = "";
          state.order_lines = [];
          // Reset order_lines
        }
      })
      .addCase(putOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = "Richiesta rifiutata durante la creazione dell'ordine";
      });
  },
});

export const { editOrder, setTable, reset, setSector } = takeOrderSlice.actions;

export const selectTakeOrder = (state: RootState) => state.takeOrderState;

export default takeOrderSlice.reducer;
