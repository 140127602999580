import { OrderState } from "./orderTypes";

export const initialState: OrderState = {
  order_lines: [],
  table_name: "",
  payment_method: "",
  loading: false,
  error: undefined,
  sector: "",
};
