import React, { memo, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  Collapse,
  IconButton,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppDispatch } from "../redux/hooks";
import { editOrder } from "./orderSlice";

export interface MenuItemProps {
  id: number;
  name: string;
  price: number;
  quantity: number;
  note?: string;
  available: number;
}

const MenuItem = memo((props: MenuItemProps) => {
  const { id, name, price, quantity, note } = props;
  const [showNotes, setShowNotes] = useState(false);
  const [localNotes, setLocalNotes] = useState(note === undefined ? "" : note);
  const dispatch = useAppDispatch();

  let backgroundColor = "#00000000";

  if (quantity > 0) {
    backgroundColor = "#73fc0322";
  }

  const toggleNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleIncrement = () => {
    dispatch(
      editOrder({
        dish_id: id,
        quantity: quantity + 1,
        notes: localNotes,
      })
    );
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      dispatch(
        editOrder({
          dish_id: id,
          quantity: quantity - 1,
          notes: localNotes,
        })
      );
    }
  };

  return (
    <div
      style={{
        paddingTop: "8px",
      }}
    >
      <Card variant="elevation" sx={{ width: "100%" }}>
        <CardContent
          sx={{
            padding: "8px",
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              <strong>{name}</strong>
            </Typography>
            <IconButton
              aria-label={showNotes ? "Nascondi Note" : "Apri Note"}
              onClick={toggleNotes}
              size="small"
              sx={{
                marginLeft: "8px",
              }}
            >
              {showNotes ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" color="textSecondary">
              Prezzo: € {price.toFixed(2)}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {props.available === 1 ? (
                <div>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleDecrement();
                    }}
                    disabled={quantity === 0}
                    size="small"
                    sx={{
                      marginLeft: "8px",
                    }}
                  >
                    -
                  </Button>
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", marginLeft: "8px" }}
                  >
                    {quantity}
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleIncrement();
                    }}
                    size="small"
                    sx={{
                      marginLeft: "8px",
                    }}
                  >
                    +
                  </Button>
                </div>
              ) : (
                <p style={{ color: "red" }}>Non disponibile</p>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              transition: "margin-top 0.3s ease-in-out",
              marginTop: showNotes ? "12px" : "0",
            }}
          >
            <Collapse in={showNotes}>
              <TextField
                label="Note"
                value={localNotes}
                onChange={(e) => {
                  setLocalNotes(e.target.value);
                  dispatch(
                    editOrder({
                      dish_id: id,
                      quantity: quantity,
                      notes: e.target.value,
                    })
                  );
                }}
                variant="outlined"
                fullWidth
                size="small"
                placeholder="Aggiungi note..."
              />
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
});

export default MenuItem;
