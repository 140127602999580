import React, { ChangeEvent } from "react";
import { TextField } from "@mui/material";

interface TextInputProps {
  label: string;
  name: string;
  value: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  type: string;
  fullWidth: boolean;
}

const TextInputComponent = (props: TextInputProps) => {
  const {
    label,
    name,
    value,
    onChange,
    helperText,
    error,
    disabled,
    type,
    fullWidth,
  } = props;

  return (
    <TextField
      margin="normal"
      required
      fullWidth={fullWidth}
      id={name}
      label={label}
      name={name}
      autoComplete={name}
      value={value}
      onChange={onChange}
      helperText={helperText}
      error={error}
      disabled={disabled}
      type={type}
    />
  );
};

export default TextInputComponent;
