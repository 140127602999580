import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./messageInitialState";
import { RootState } from "../redux/store";

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
    },
  },
});

export const { setMessage } = messageSlice.actions;

export const selectMessage = (state: RootState) => state.takeOrderState;

export default messageSlice.reducer;
