import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../redux/store";
import { initialState } from "./debugInitialState";

export const debugSlice = createSlice({
  name: "debug",
  initialState,
  reducers: {
    Append: (state, action: PayloadAction<string>) => {
      state.debug_info.push(action.payload);
      //keep only the latest 250 messages
      if (state.debug_info.length > 250) {
        state.debug_info.shift();
      }
    },
  },
});

export const { Append } = debugSlice.actions;

export const selectdebug = (state: RootState) => state.debugState;

export default debugSlice.reducer;
