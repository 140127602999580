import { Divider, Grid, Typography } from "@mui/material";
import { useAppSelector } from "../redux/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

interface TakeOrderComponentBodyProps {
  for_client: boolean;
}

function OrderHistoryComponentCopy({
  for_client,
}: TakeOrderComponentBodyProps) {
  const orders = useAppSelector((state) => state.ordersState.orders);
  const { id } = useParams();
  const menu = useAppSelector((state) => state.menuState.menu);
  const order = orders.find((order) => order.id.toString() === id);

  const total = order?.order_lines
    .map((line) => {
      const menuEntry = menu
        .flatMap((category) => category.dishes)
        .find((entry) => entry.id === line.dish_id);
      if (menuEntry) {
        return line.quantity * menuEntry.price;
      } else {
        return 0;
      }
    })
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

  const formatDate = (dateString: string | undefined) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateString ?? "");
    return date.toLocaleDateString(undefined, options);
  };

  const hasTakeAway = order?.order_lines.every(
    (line) =>
      menu
        .flatMap((category) => category.dishes)
        .find((entry) => entry.id === line.dish_id)?.is_take_away ?? false
  );

  useEffect(() => {
    window.onafterprint = window.close;
    window.print();
  }, []);

  return (
    <div>
      <p>
        Oratorio Bevera Tavola Calda 2024
        <br />
        Tavolo {order?.table_name} {hasTakeAway ? "DA ASPORTO" : ""} <br />
        {formatDate(order?.creation_date)}
      </p>
      <Grid container key="header" spacing={0} alignItems="center">
        <Grid item xs={8} paddingLeft={0}>
          <div
            style={{
              margin: "0 auto",
              textAlign: "left",
            }}
          >
            Descrizione
          </div>
        </Grid>
        <Grid item xs={1} paddingLeft={0}>
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            Q.tà
          </div>
        </Grid>
        <Grid item xs={3} paddingLeft={0}>
          <div
            style={{
              margin: "0 auto",
              textAlign: "right",
            }}
          >
            Importo
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          fontSize: "16px",
          maxWidth: "400px",
          margin: "0 auto",
          textAlign: "left",
        }}
      >
        {menu
          .flatMap((category) => category.dishes)
          .map((dish, index) => {
            const line = order?.order_lines.find(
              (line) => line.dish_id === dish.id
            );
            if (line !== undefined) {
              return (
                <div key={index+"div"}>
                <Grid container key={index} spacing={0} alignItems="center">
                  <Grid item xs={8} paddingLeft={0}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "0 auto",
                        textAlign: "left",
                      }}
                    >
                      {(for_client ? "" : "□  ") + dish.name}
                    </div>
                  </Grid>
                  <Grid item xs={1} paddingLeft={0}>
                    <div
                      style={{
                        fontSize: "12px",
                        margin: "0 auto",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {line.quantity}
                    </div>
                  </Grid>
                  <Grid item xs={3} paddingLeft={0}>
                    <div
                      style={{
                        fontSize: "12px",
                        margin: "0 auto",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      {(line.quantity * (dish?.price ?? 0)).toFixed(2)}€
                    </div>
                  </Grid>
                </Grid>
                {
                  line?.notes ? (
                    <div
                      style={{
                        fontSize: "12px",
                        margin: "0 auto",
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >&nbsp;&nbsp;Note: {line?.notes}</div>
                ) : null
              }
              </div>
              );
            }
          })}
      </div>
      <p>Totale: {total} €</p>
      {for_client ? null : <Divider />}
    </div>
  );
}

function OrderHistoryComponentBody() {
  return (
    <div
      style={{
        fontSize: "12px",
        fontWeight: "bold",
        maxWidth: "400px",
        textAlign: "center",
        fontFamily: "Arial, Helvetica, sans-serif",
      }}
    >
      <OrderHistoryComponentCopy for_client={true} />
    </div>
  );
}

export default OrderHistoryComponentBody;
