import DrawerAppBarHOC from "../home/DrawerAppBar";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MenuEntryComponent from "./MenuEntryComponent";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Dish, IDishGroup } from "../menu/menuTypes";
import { HttpRequest } from "../base/HttpModels";
import { PutOrderRequest } from "./orderTypes";
import { putOrder, reset, setSector, setTable } from "./orderSlice";
import { useState, useEffect } from "react";
import React from "react";
import { isMobile } from "react-device-detect";
import withAuthentication from "../base/AuthComponent";
import { BaseRequest } from "../base/BaseRequest";
import { MENU_LIST } from "../menu/menuActions";
import { getMenuList } from "../menu/menuSlice";

interface TakeOrderComponentBodyProps {
  isTakeAway: number;
}

export function TakeOrderComponentBody({
  isTakeAway,
}: TakeOrderComponentBodyProps) {
  const menu = useAppSelector((state) => state.menuState.menu);
  const [dishes, setDishes] = useState<Dish[]>([]);
  const lines = useAppSelector((state) => state.takeOrderState.order_lines);
  const sector = useAppSelector((state) => state.takeOrderState.sector);
  const tableNumber = useAppSelector(
    (state) => state.takeOrderState.table_name
  );
  const token = useAppSelector((state) => state.loginState.token);
  const loading = useAppSelector((state) => state.takeOrderState.loading);

  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSector((event.target as HTMLInputElement).value));
  };

  useEffect(() => {
    if (token === "" || token === undefined) {
      return;
    }
    const menuRequest: HttpRequest<BaseRequest> = {
      action: MENU_LIST,
      parameters: {
        user_token: token,
      },
    };
    dispatch(getMenuList(menuRequest));
  }, [token, dispatch]);

  useEffect(() => {
    setDishes(menu.flatMap((category) => category.dishes));
  }, [menu, isTakeAway]);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      key={"menu_root"}
      sx={{
        width: "100%",
        paddingLeft: isMobile ? "0%" : "25%",
        paddingRight: isMobile ? "0%" : "25%",
        display: "block",
        alignItems: "left",
      }}
    >
      <Typography
        key={"settore"}
        component="h1"
        variant="h5"
        align="center"
        sx={{ marginTop: "16px" }}
      >
        Settore
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={sector}
          onChange={handleChange}
        >
          <FormControlLabel value="rosso" control={<Radio />} label="Rosso" />
          <FormControlLabel value="blu" control={<Radio />} label="Blu" />
          <FormControlLabel value="giallo" control={<Radio />} label="Giallo" />
          <FormControlLabel value="verde" control={<Radio />} label="Verde" />
        </RadioGroup>
      </FormControl>
      <TextField
        label="Tavolo"
        variant="outlined"
        fullWidth
        size="small"
        placeholder="Numero tavolo..."
        sx={{ marginTop: "16px" }}
        value={tableNumber}
        onChange={(event) => dispatch(setTable(event.target.value))}
      />
      {menu
        .filter(
          (group) =>
            group.dishes.filter((dish) => dish.is_take_away === isTakeAway)
              .length > 0
        )
        .map((group: IDishGroup) => (
          <div key={"containter" + group.id}>
            <Typography
              key={group.id}
              component="h1"
              variant="h5"
              align="center"
              sx={{ marginTop: "16px" }}
            >
              {group.name}
            </Typography>
            {group.dishes.map((entry) => (
              <MenuEntryComponent
                key={entry.id}
                id={entry.id}
                name={entry.name}
                price={entry.price}
                note={entry.note}
                available={entry.is_enabled}
                quantity={
                  (
                    lines.find((order) => order.dish_id === entry.id) || {
                      quantity: 0,
                    }
                  ).quantity
                }
              />
            ))}
          </div>
        ))}
      <Typography
        component="h1"
        variant="h5"
        align="center"
        style={{
          paddingTop: "16px",
        }}
      >
        Totale: €
        {lines
          .map((line) => {
            const menuEntry = dishes.find((entry) => entry.id === line.dish_id);
            if (menuEntry) {
              return line.quantity * menuEntry.price;
            } else {
              return 0;
            }
          })
          .reduce((a, b) => a + b, 0).toFixed(2)}
      </Typography>
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        onClick={() => {
          var request: HttpRequest<PutOrderRequest> = {
            action: "order_put",
            parameters: {
              user_token: token ? token : "",
              table_name: sector + "-" + tableNumber,
              order_lines: lines,
              payment_method: "cash",
            },
          };
          dispatch(putOrder(request));
        }}
        disabled={
          tableNumber === "" ||
          token === "" ||
          token === undefined ||
          sector === ""
        }
      >
        Invia ordine
      </Button>
      <Box sx={{ height: "16px" }} />
      <Button
        type="button"
        fullWidth
        variant="outlined"
        sx={{ mt: 2 }}
        onClick={() => {
          dispatch(reset());
        }}
      >
        Annulla
      </Button>
    </Box>
  );
}

function TakeOrderAwayComponent() {
  return (
    <DrawerAppBarHOC
      title="Prendi ordine Asporto"
      content={<TakeOrderComponentBody isTakeAway={1} />}
    />
  );
}

export default withAuthentication(TakeOrderAwayComponent);
