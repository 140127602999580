import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../redux/store";
import { initialState } from "./loginInitialState";
import {
  HttpRequest,
  LoginRequest,
  LoginRequestResponse,
  LoginResponse,
} from "../base/HttpModels";
import axios from "axios";
import { SetCheckAuthParametersPayload } from "./loginTypes";
import {
  USER_CHECK_AUTH,
  USER_LOGIN_ADMIN,
  USER_REQUEST_LOGIN,
} from "./loginActions";
import { REQUEST_URL } from "../base/Constants";
import { logMessage } from "../utils/logger";

export const userCheckAuth = createAsyncThunk(
  USER_CHECK_AUTH,
  async (request: HttpRequest<LoginRequest>) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[userCheckAuth] " + JSON.stringify(response));
      return response.data.results[0] as LoginResponse;
    } catch (error) {
      logMessage("[userCheckAuth] " + JSON.stringify(error));
      return null;
    }
  }
);

export const userRequestLogin = createAsyncThunk(
  USER_REQUEST_LOGIN,
  async (request: HttpRequest<LoginRequest>) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[userRequestLogin] " + JSON.stringify(response));
      if (response.data.results.length > 0) {
        return response.data.results[0] as LoginRequestResponse;
      } else {
        return null;
      }
    } catch (error) {
      logMessage("[userRequestLogin] " + JSON.stringify(error));
      return null;
    }
  }
);

export const userLoginAdmin = createAsyncThunk(
  USER_LOGIN_ADMIN,
  async (request: HttpRequest<LoginRequest>) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[userLoginAdmin] " + response);
      return response.data.results[0] as LoginResponse;
    } catch (error) {
      logMessage("[userLoginAdmin] " + error);
      return null;
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    Logout: (state) => {
      state.device_id = "";
      state.name = "";
      state.role = -1;
      state.status = "";
      state.token = undefined;
      state.error = undefined;
    },
    SetLoginRequestParams: (
      state,
      action: PayloadAction<SetCheckAuthParametersPayload>
    ) => {
      state.device_id = action.payload.device_id;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.loading = true;
    },
    ResetErrorMessage: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAdmin.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(userLoginAdmin.fulfilled, (state, action) => {
        if (action.payload) {
          state.error = undefined;
          if (action.payload) {
            state.confirmed = true;
            state.status = action.payload.status;
            state.token = action.payload.token;
          }
        } else {
          state.error = "Error during " + USER_REQUEST_LOGIN;
        }
        state.loading = false;
      })
      .addCase(userLoginAdmin.rejected, (state, action) => {
        state.error = USER_REQUEST_LOGIN + " rejected";
        state.loading = false;
      })
      .addCase(userRequestLogin.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(userRequestLogin.fulfilled, (state, action) => {
        if (action.payload) {
          state.error = undefined;
          state.pin = action.payload.pin;
          state.status = "pending";
        } else {
          state.error = "Error during " + USER_REQUEST_LOGIN;
        }
        state.loading = false;
      })
      .addCase(userRequestLogin.rejected, (state, action) => {
        state.error = USER_REQUEST_LOGIN + " rejected";
        state.loading = false;
      })
      .addCase(userCheckAuth.fulfilled, (state, action) => {
        if (action.payload) {
          state.error = undefined;
          state.status = action.payload.status;
          state.token = action.payload.token;
        } else {
          state.error = "Error during " + USER_CHECK_AUTH;
          state.status = "";
        }
      })
      .addCase(userCheckAuth.rejected, (state, action) => {
        state.error = USER_CHECK_AUTH + " rejected";
        state.status = "";
      });
  },
});

export const { SetLoginRequestParams, ResetErrorMessage, Logout } =
  loginSlice.actions;

export const selectMenu = (state: RootState) => state.menuState;

export default loginSlice.reducer;
