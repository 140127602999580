import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { LoginComponent } from "../login/LoginComponent";
import { PollingComponent } from "../login/PollingComponent";
import { DeniedComponent } from "../login/DeniedComponent";
import { getMenuList } from "../menu/menuSlice";
import { BaseRequest } from "./BaseRequest";
import { MENU_LIST } from "../menu/menuActions";
import { HttpRequest } from "./HttpModels";
import { getUserList } from "../users/usersSlice";
import { USER_LIST } from "../users/usersActions";
import { Box, Button, CircularProgress } from "@mui/material";
import MessageComponent from "../message/MessageComponent";
import { logMessage } from "../utils/logger";
import { isMobile } from "react-device-detect";
import { Logout } from "../login/loginSlice";

const withAuthentication = (WrappedComponent: any) => {
  const AuthHOC = () => {
    const token = useAppSelector((state) => state.loginState.token);
    const status = useAppSelector((state) => state.loginState.status);
    const menu = useAppSelector((state) => state.menuState.menu);
    const users = useAppSelector((state) => state.userState.users);
    const message = useAppSelector((state) => state.messageState.message);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (
        token !== null &&
        token !== undefined &&
        token !== ""
      ) {
        console.log("[getUserList] Getting user list");
        const userRequest: HttpRequest<BaseRequest> = {
          action: USER_LIST,
          parameters: {
            user_token: token,
          },
        };
        dispatch(getUserList(userRequest));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token]);

    useEffect(() => {
      if (
        token !== null &&
        token !== undefined &&
        token !== ""
      ) {
        console.log(">>> [getMenuList] Getting menu list");
        const menuRequest: HttpRequest<BaseRequest> = {
          action: MENU_LIST,
          parameters: {
            user_token: token,
          },
        };
      
        dispatch(getMenuList(menuRequest));
      }
    }, [dispatch, token]);

    if (token == null || token === undefined || token === "") {
      if (status === "" || status === undefined || status == null) {
        return <LoginComponent />;
      }
      if (status === "pending") {
        return <PollingComponent />;
      }
      if (status === "denied") {
        return <DeniedComponent />;
      }
    }

    if (menu === undefined || menu == null || menu.length === 0) {
      return (
        <Box
          sx={{
            width: "100%",
            paddingLeft: isMobile ? "0%" : "25%",
            paddingRight: isMobile ? "0%" : "25%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <CircularProgress />
          </Box>
      );
    }

    return (
      <div>
        {message ? <MessageComponent /> : <></>}
        <WrappedComponent />
      </div>
    );
  };

  return AuthHOC;
};

export default withAuthentication;
