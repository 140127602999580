import { Container, Box, Typography, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { roleFromInt } from "../utils/roles";
import { SpaceComponent } from "../base/SpaceComponent";
import { TitleComponent } from "./TitleComponent";
import { Logout } from "./loginSlice";

export const DeniedComponent = () => {
  /**
   * Redux
   */
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((state) => state.loginState);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitleComponent title="Oratorio Bevera Tavola Calda 2024" />
        <SpaceComponent />
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Nome: {loginState.name}
        </Typography>
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Ruolo: {roleFromInt(loginState.role)}
        </Typography>
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Id: {loginState.device_id}
        </Typography>
        <SpaceComponent />
        <Typography variant="body2" sx={{ my: 1 }} textAlign="center">
          L'amministratore ha rifiutato la tua registrazione.
        </Typography>
        <Button sx={{ color: "#fff" }} onClick={(event) => dispatch(Logout())}>
          Riprova
        </Button>
      </Box>
    </Container>
  );
};
