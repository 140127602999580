import { Box } from "@mui/material";
import withAuthentication from "../base/AuthComponent";
import DrawerAppBarHOC from "../home/DrawerAppBar";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect } from "react";
import { HttpRequest } from "../base/HttpModels";
import { USER_LIST } from "./usersActions";
import { BaseRequest } from "../base/BaseRequest";
import { getUserList } from "./usersSlice";
import { UserListItemComponent } from "./UserListItemComponent";
import { isMobile } from "react-device-detect";

const UserListComponentBody = () => {
  const users = useAppSelector((state) => state.userState.users);
  const token = useAppSelector((state) => state.loginState.token);
  const role = useAppSelector((state) => state.loginState.role);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token !== undefined && token !== null && token !== "") {
      const userRequest: HttpRequest<BaseRequest> = {
        action: USER_LIST,
        parameters: {
          user_token: token,
        },
      };
      dispatch(getUserList(userRequest));
    }
  }, [dispatch, token]);

   useEffect(() => {
     const interval = setInterval(() => {
       if (token !== undefined && token !== null && token !== "") {
         const userRequest: HttpRequest<BaseRequest> = {
           action: USER_LIST,
           parameters: {
             user_token: token,
           },
         };
         dispatch(getUserList(userRequest));
       }
     }, 30000);
     return () => {
       clearInterval(interval);
     };
   }, []);

  return (
    <Box
      sx={{
        width: "100%",
        paddingLeft: isMobile ? "0%" : "25%",
        paddingRight: isMobile ? "0%" : "25%",
        display: "block",
        alignItems: "center",
      }}
    >
      {[...users].reverse().map((item) => (
        <UserListItemComponent
          key={item.id}
          item={item}
          token={token}
          canDelete={
            role === 1 && item.status !== "pending" && item.role_id !== 1
          }
          canEdit={
            role !== 3 && role <= item.role_id && item.status === "pending"
          }
        />
      ))}
    </Box>
  );
};

function UserListComponent() {
  return (
    <DrawerAppBarHOC title="Lista utenti" content={<UserListComponentBody />} />
  );
}

export default withAuthentication(UserListComponent);
