import withAuthentication from "../base/AuthComponent";
import DrawerAppBarHOC from "../home/DrawerAppBar";
import { TakeOrderComponentBody } from "./TakeOrderComponent";

function TakeOrderTableComponent() {
  return (
    <DrawerAppBarHOC
      title="Prendi ordine al Tavolo"
      content={<TakeOrderComponentBody isTakeAway={0} />}
    />
  );
}
export default withAuthentication(TakeOrderTableComponent);
