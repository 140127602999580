import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Alert,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Container, Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { TitleComponent } from "./TitleComponent";
import { SpaceComponent } from "../base/SpaceComponent";
import TextInputComponent from "./TextInputComponent";
import {
  SetLoginRequestParams,
  userLoginAdmin,
  userRequestLogin,
} from "./loginSlice";
import { HttpRequest, LoginRequest } from "../base/HttpModels";
import { USER_LOGIN_ADMIN, USER_REQUEST_LOGIN } from "./loginActions";
import {
  AMMINISTRATORE,
  CAMERIERE,
  CASSIERE,
  intFromRole,
} from "../utils/roles";
import { logMessage } from "../utils/logger";

export const LoginComponent = () => {
  /**
   * Redux
   */
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((state) => state.loginState);

  /**
   * Hooks
   */
  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const [role, setRole] = useState<string>("Cameriere");

  /*
  useEffect(() => {
    if (loginState.error !== undefined) {
      dispatch(ResetErrorMessage());
    }
  }, [username, password, role, dispatch, loginState.error]);
*/

  // extract a random character between a and z and 0 and 9
  const randomChar = () => {
    const chars = "abcdefghijklmnpqrstuvwxyz0123456789";
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  // extract a random guid using the randomChar function
  const randomGuid = () => {
    let guid = "";
    for (let i = 0; i < 32; i++) {
      //add a - every 8-4-4-4-8 characters
      if (i === 8 || i === 12 || i === 16 || i === 20) {
        guid += "-";
      }
      guid += randomChar();
    }
    logMessage(guid);
    return guid;
  };

  /**
   * Login button handler
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (username == null) {
      return;
    }

    const device_id = randomGuid();
    const action = role === CAMERIERE ? USER_REQUEST_LOGIN : USER_LOGIN_ADMIN;

    const request: HttpRequest<LoginRequest> = {
      action: action,
      parameters: {
        name: username,
        password: password,
        role_id: intFromRole(role),
        device_id: device_id,
        sagra_id: "1",
      },
    };
    dispatch(
      SetLoginRequestParams({
        name: username,
        device_id: device_id,
        role: intFromRole(role),
      })
    );
    if (role === CAMERIERE) {
      dispatch(userRequestLogin(request));
    } else {
      dispatch(userLoginAdmin(request));
    }
  };

  /**
   * Support check
   */
  const isButtonDisabled =
    !username ||
    username.length < 3 ||
    !role ||
    loginState.loading ||
    ((role === AMMINISTRATORE || role === CASSIERE) && !password);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitleComponent title="Oratorio Bevera Tavola Calda 2024" />
        <SpaceComponent />
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Ruolo</InputLabel>
            <Select
              disabled={loginState.loading}
              sx={{ width: 300 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Ruolo"
              onChange={(event: SelectChangeEvent) =>
                setRole(event.target.value)
              }
            >
              <MenuItem value={CAMERIERE}>Cameriere</MenuItem>
              <MenuItem value={CASSIERE}>Cassiere</MenuItem>
              <MenuItem value={AMMINISTRATORE}>Amministratore</MenuItem>
            </Select>
          </FormControl>
          <SpaceComponent />
          <TextInputComponent
            fullWidth={true}
            label="Nome Utente"
            name="nome_utente"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            helperText={
              username === null
                ? ""
                : username.length < 3
                ? "Almeno 3 caratteri richiesti"
                : !/^[a-zA-Z0-9_\-]{3,24}$/.test(username)
                ? "Caratteri non validi"
                : ""
            }
            error={
              username !== null &&
              ((username.length > 0 && username.length < 3) ||
                !/^[a-zA-Z0-9_\-]{3,24}$/.test(username))
            }
            disabled={loginState.loading}
          />
          <SpaceComponent />
          {(role === AMMINISTRATORE || role === CASSIERE) && (
            <div>
              <SpaceComponent />
              <TextInputComponent
                fullWidth={true}
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loginState.loading}
              />
            </div>
          )}
          {!loginState.loading && (
            <div>
              <SpaceComponent />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
                disabled={isButtonDisabled}
              >
                {role === CAMERIERE ? "Richiedi accesso" : "Login"}
              </Button>
            </div>
          )}
          {loginState.loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "32px",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {loginState.error && (
            <div>
              <SpaceComponent />
              <Alert severity="error">{loginState.error}</Alert>
            </div>
          )}
        </Box>
      </Box>
    </Container>
  );
};
