import {
  Container,
  Box,
  Typography,
  LinearProgress,
  Grid,
  Button,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { roleFromInt } from "../utils/roles";
import { SpaceComponent } from "../base/SpaceComponent";
import { TitleComponent } from "./TitleComponent";
import { useEffect } from "react";
import { USER_CHECK_AUTH, USER_REQUEST_LOGIN } from "./loginActions";
import { HttpRequest, LoginRequest } from "../base/HttpModels";
import { Logout, userCheckAuth } from "./loginSlice";
import { logMessage } from "../utils/logger";

export const PollingComponent = () => {
  /**
   * Redux
   */
  const dispatch = useAppDispatch();
  const loginState = useAppSelector((state) => state.loginState);

  useEffect(() => {
    const interval = setInterval(() => {
      const request: HttpRequest<LoginRequest> = {
        action: USER_CHECK_AUTH,
        parameters: {
          name: loginState.name,
          password: "",
          device_id: loginState.device_id,
          role_id: loginState.role,
          sagra_id: "1",
        },
      };
      dispatch(userCheckAuth(request));
    }, 5000);

    return () => {
      logMessage("interval cleared");
      clearInterval(interval);
    };
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitleComponent title="Oratorio Bevera Tavola Calda 2024" />
        <SpaceComponent />
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Nome: {loginState.name}
        </Typography>
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Ruolo: {roleFromInt(loginState.role)}
        </Typography>
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Id: {loginState.device_id}
        </Typography>
        <SpaceComponent />
        <Typography variant="body1" sx={{ my: 1 }} textAlign="center">
          Mostra questo PIN all'amministratore:
        </Typography>
        <Typography variant="h1" sx={{ my: 1 }} textAlign="center">
          {loginState.pin}
        </Typography>
        <SpaceComponent />
        <Typography variant="body2" sx={{ my: 1 }} textAlign="center">
          In attesa di conferma...
        </Typography>
        <Grid spacing={1} container>
          <Grid xs item>
            <LinearProgress variant="indeterminate" title="waiting" />
          </Grid>
        </Grid>
        <Box sx={{ m: 3 }} />
        <Button
          variant="outlined"
          onClick={(event) => {
            dispatch(Logout());
          }}
        >
          Annulla
        </Button>
      </Box>
    </Container>
  );
};
