import React, { useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, createTheme, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import OrderHistoryComponent from "./order_history/OrderHistoryComponent";
import UserListComponent from "./users/UserListComponent";
import { DebugComponent } from "./debug/DebugComponent";
import DrawerAppBarHOC from "./home/DrawerAppBar";
import OrderDetailComponent from "./order_history/OrderDetailComponent";
import TakeOrderAwayComponent from "./take_order/TakeOrderComponent";
import TakeOrderTableComponent from "./take_order/TakeOrderTableComponent";
import ManageMenuComponent from "./manage/manageMenuComponent";
import StatsComponent from "./stats/statsComponent";
import OrderPreviewComponent from "./order_history/OrderPreviewComponent";

const router = createHashRouter([
  {
    path: "/",
    element: <TakeOrderTableComponent />,
  },
  {
    path: "/new_order_table",
    element: <TakeOrderTableComponent />,
  },
  {
    path: "/new_order_takeaway",
    element: <TakeOrderAwayComponent />,
  },
  {
    path: "/list_orders",
    element: <OrderHistoryComponent />,
  },
  {
    path: "/order_detail/:id",
    element: <OrderDetailComponent />,
  },
  {
    path: "order_preview/:id",
    element: <OrderPreviewComponent />,
  },
  {
    path: "/list_users",
    element: <UserListComponent />,
  },
  {
    path: "/manage_menu",
    element: <ManageMenuComponent />,
  },
  {
    path: "/stats",
    element: <StatsComponent />,
  },
  {
    path: "/debug",
    element: (
      <DrawerAppBarHOC title="Debug info" content={<DebugComponent />} />
    ),
  },
]);

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  useEffect(() => {
    fetch("build_date.txt").then((c) => c.text().then((v) => console.log(v)));
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}
export default App;
