import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setMessage } from "./messageSlice";

export default function MessageComponent() {
  const dispatch = useAppDispatch();

  const message = useAppSelector((state) => state.messageState.message);

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => {
          dispatch(setMessage(undefined));
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Messaggio di sistema"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setMessage(undefined));
            }}
            autoFocus
            disabled={isButtonDisabled}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
