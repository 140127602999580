import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { HttpRequest } from "../base/HttpModels";
import { IChangeOrderStatusRequest } from "./ordersTypes";
import { ORDER_CHANGE_STATUS } from "./orderHistoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { changeOrderStatus } from "./ordersSlice";
import { CAMERIERE, intFromRole } from "../utils/roles";

export interface OrderHistoryItemProps {
  id: number;
  date: Date;
  table: string;
  takenBy?: string;
  paidBy?: string;
  servedBy?: string;
  closedBy?: string;
  price: number;
}

const OrderHistoryItem = (props: OrderHistoryItemProps) => {
  const { id, date, table, takenBy, paidBy, servedBy, closedBy, price } = props;
  const token = useAppSelector((state) => state.loginState.token);
  const role = useAppSelector((state) => state.loginState.role);
  const dispatch = useAppDispatch();

  let backgroundColor = "#ff000022";

  if (paidBy) {
    backgroundColor = "#fcba0322";
  }

  if (servedBy) {
    backgroundColor = "#ecfc0322";
  }

  if (closedBy) {
    backgroundColor = "#73fc0322";
  }

  return (
    <div
      style={{
        paddingTop: "8px",
      }}
    >
      <Card variant="elevation" sx={{ width: "100%" }}>
        <CardContent
          sx={{
            padding: "8px",
            backgroundColor: backgroundColor,
          }}
        >
          <Grid container>
            <Grid item xs={6} alignItems="center">
              <Typography variant="h6" align="left">
                Ordine: {id}
              </Typography>
            </Grid>
            <Grid item xs={6} alignItems="center">
              <Typography variant="h6" align="right">
                {date.getDate().toString().padStart(2, "0") +
                  "/" +
                  (date.getMonth() + 1).toString().padStart(2, "0") +
                  "/" +
                  date.getFullYear() +
                  " " +
                  date.getHours().toString().padStart(2, "0") +
                  ":" +
                  date.getMinutes().toString().padStart(2, "0")}
              </Typography>
            </Grid>
            <Grid item xs={6} alignItems="center">
              <Typography variant="h6" align="left">
                Tavolo: {table}
              </Typography>
            </Grid>
            <Grid item xs={6} alignItems="center">
              <Typography variant="h6" align="right">
                Totale: € {price}
              </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center">
              <Typography variant="body1" align="left">
                Ordine preso da: <b>{takenBy}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center">
              {paidBy ? (
                <Typography variant="body1" align="left">
                  Segnato pagato da: <b>{paidBy}</b>
                </Typography>
              ) : (
                <Box textAlign="center">
                  <Button
                    variant="outlined"
                    disabled={token === undefined || role === 3} // Only cashier+ can mark orders as paid
                    onClick={(event) => {
                      if (token === undefined) {
                        return;
                      }
                      const request: HttpRequest<IChangeOrderStatusRequest> = {
                        action: ORDER_CHANGE_STATUS,
                        parameters: {
                          order_id: id,
                          order_status_id: 2, // PAID
                          user_token: token,
                        },
                      };
                      dispatch(changeOrderStatus(request));
                    }}
                  >
                    Segna come pagato
                  </Button>
                </Box>
              )}
            </Grid>
            {paidBy ? (
              <Grid item xs={12} alignItems="center" sx={{ marginTop: "8px" }}>
                {servedBy ? (
                  <Typography variant="body1" align="left">
                    Preso in carico da: <b>{servedBy}</b>
                  </Typography>
                ) : role !== intFromRole(CAMERIERE) ? (
                  <Box textAlign="center">
                    <Button
                      variant="outlined"
                      onClick={(event) => {
                        if (token === undefined) {
                          return;
                        }
                        const request: HttpRequest<IChangeOrderStatusRequest> =
                          {
                            action: ORDER_CHANGE_STATUS,
                            parameters: {
                              order_id: id,
                              order_status_id: 3, // SERVED
                              user_token: token,
                            },
                          };
                        dispatch(changeOrderStatus(request)).then((result) => {
                          if (result.payload !== null) {
                            window.open(
                              window.location.origin + "/#/order_detail/" + id,
                              "_blank",
                              "width=400"
                            );
                          }
                        });
                      }}
                    >
                      Stampa
                    </Button>
                  </Box>
                ) : null}
              </Grid>
            ) : null}
            {servedBy ? (
              <Grid item xs={12} alignItems="center" sx={{ marginTop: "8px" }}>
                {closedBy ? (
                  <Typography variant="body1" align="left">
                    Completato da: <b>{closedBy}</b>
                  </Typography>
                ) : (
                  <Box textAlign="center">
                    <Button
                      variant="outlined"
                      onClick={(event) => {
                        if (token === undefined) {
                          return;
                        }
                        const request: HttpRequest<IChangeOrderStatusRequest> =
                          {
                            action: ORDER_CHANGE_STATUS,
                            parameters: {
                              order_id: id,
                              order_status_id: 4, // COMPLETED
                              user_token: token,
                            },
                          };
                        dispatch(changeOrderStatus(request));
                      }}
                    >
                      Segna come completato
                    </Button>
                  </Box>
                )}
              </Grid>
            ) : null}
          </Grid>
          {servedBy && !closedBy && role !== intFromRole(CAMERIERE) ? (
            <Grid item xs={12} alignItems="center" sx={{ marginTop: "8px" }}>
              <Box textAlign="center">
                <Button
                  variant="outlined"
                  onClick={(event) => {
                    window.open(
                      window.location.origin + "/#/order_detail/" + id,
                      "_blank",
                      "width=400"
                    );
                  }}
                >
                  Ristampa
                </Button>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} alignItems="center" sx={{ marginTop: "8px" }}>
            <Box textAlign="center">
              <Button
                variant="outlined"
                onClick={(event) => {
                  window.open(
                    window.location.origin + "/#/order_preview/" + id,
                    "_blank"
                  );
                }}
              >
                Visualizza
              </Button>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default OrderHistoryItem;
