import { PendingRounded, DoNotDisturbAltRounded } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { HttpRequest } from "../base/HttpModels";
import { USER_DELETE, USER_SET_ACCEPTED } from "./usersActions";
import { userDelete, userSetAccepted } from "./usersSlice";
import { AcceptRequest, DeleteRequest, User } from "./usersTypes";
import { roleFromInt } from "../utils/roles";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useAppDispatch } from "../redux/hooks";
import TextInputComponent from "../login/TextInputComponent";
import { useState } from "react";

export interface UserListItemProps {
  item: User;
  token?: string;
  canEdit: boolean;
  canDelete: boolean;
}

export const UserListItemComponent = (props: UserListItemProps) => {
  const dispatch = useAppDispatch();

  const [pin, setPin] = useState<string>("");

  let backgroundColor = "#ff000022";

  if (props.item.status.toLowerCase() === "pending") {
    backgroundColor = "#fcba0322";
  }

  if (props.item.status.toLowerCase() === "accepted") {
    backgroundColor = "#73fc0322";
  }

  return (
    <div
      key={props.item.id}
      style={{
        paddingTop: "8px",
      }}
    >
      <Card variant="elevation" sx={{ width: "100%" }}>
        <CardContent
          sx={{
            padding: "8px",
            backgroundColor: backgroundColor,
          }}
        >
          <Grid container>
            <Grid item={true} xs={1} alignItems="center">
              {props.item.status.toLowerCase() === "accepted" ? (
                <CheckRoundedIcon />
              ) : null}
              {props.item.status.toLowerCase() === "pending" ? (
                <PendingRounded />
              ) : null}
              {props.item.status.toLowerCase() === "denied" ? (
                <DoNotDisturbAltRounded />
              ) : null}
            </Grid>
            <Grid item={true} xs={5} alignItems="center">
              <Typography variant="body1" align="left">
                {props.item.name}
              </Typography>
            </Grid>
            <Grid item={true} xs={6} alignItems="center">
              <Typography variant="body1" align="right">
                {roleFromInt(props.item.role_id)}
              </Typography>
            </Grid>
          </Grid>
          {props.canDelete ? (
            <Grid item={true} xs={12} alignItems="center">
              <Box textAlign="center">
                <Button
                  sx={{
                    marginLeft: "8px",
                  }}
                  variant="outlined"
                  onClick={(event) => {
                    if (props.token === undefined) {
                      return;
                    }
                    const request: HttpRequest<DeleteRequest> = {
                      action: USER_DELETE,
                      parameters: {
                        user_id: props.item.id,
                        user_token: props.token,
                      },
                    };
                    dispatch(userDelete(request));
                  }}
                >
                  Elimina
                </Button>
              </Box>
            </Grid>
          ) : null}
          {props.canEdit ? (
            <Grid
              item={true}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <TextInputComponent
                fullWidth={false}
                label="PIN"
                name="insert_pin"
                type="text"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                error={false}
                disabled={false}
              />
              <Button
                sx={{
                  marginRight: "8px",
                  marginLeft: "8px",
                }}
                disabled={pin.length !== 6}
                variant="contained"
                onClick={(event) => {
                  if (props.token === undefined) {
                    return;
                  }
                  const request: HttpRequest<AcceptRequest> = {
                    action: USER_SET_ACCEPTED,
                    parameters: {
                      user_id: props.item.id,
                      is_accepted: true,
                      user_token: props.token,
                      pin: pin,
                    },
                  };
                  dispatch(userSetAccepted(request));
                }}
              >
                Accetta
              </Button>
              <Button
                sx={{
                  marginLeft: "8px",
                }}
                variant="outlined"
                onClick={(event) => {
                  if (props.token === undefined) {
                    return;
                  }
                  const request: HttpRequest<AcceptRequest> = {
                    action: USER_SET_ACCEPTED,
                    parameters: {
                      user_id: props.item.id,
                      is_accepted: false,
                      user_token: props.token,
                      pin: pin,
                    },
                  };
                  dispatch(userSetAccepted(request));
                }}
              >
                Rifiuta
              </Button>
            </Grid>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
};
