import { useEffect } from "react";
import { BaseRequest } from "../base/BaseRequest";
import { HttpRequest } from "../base/HttpModels";
import { DISH_UPDATE, MENU_LIST } from "../menu/menuActions";
import { getMenuList, updateDish } from "../menu/menuSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { IDishGroup, IUpdateDishRequest } from "../menu/menuTypes";
import withAuthentication from "../base/AuthComponent";
import DrawerAppBarHOC from "../home/DrawerAppBar";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";

function ManageMenuComponentBody() {
  const menu = useAppSelector((state) => state.menuState.menu);
  const token = useAppSelector((state) => state.loginState.token);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token === "" || token === undefined) {
      return;
    }
    const menuRequest: HttpRequest<BaseRequest> = {
      action: MENU_LIST,
      parameters: {
        user_token: token,
      },
    };
    dispatch(getMenuList(menuRequest));
  }, [token, dispatch]);

  return (
    <Box
      key={"menu_root"}
      sx={{
        width: "100%",
        paddingLeft: isMobile ? "0%" : "25%",
        paddingRight: isMobile ? "0%" : "25%",
        display: "block",
        alignItems: "left",
      }}
    >
      {menu.map((group: IDishGroup) => (
        <div key={group.id}>
          <h2>{group.name}</h2>
          {group.dishes.map((dish) => (
            <div
              style={{
                paddingTop: "8px",
              }}
            >
              <Card variant="elevation" sx={{ width: "100%" }}>
                <CardContent
                  sx={{
                    padding: "8px",
                    backgroundColor: dish.is_enabled
                      ? "#00000000"
                      : "#22220000",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">{dish.name}</Typography>
                    <Button
                      variant={dish.is_enabled ? "outlined" : "contained"}
                      color="primary"
                      onClick={(event) => {
                        var request: HttpRequest<IUpdateDishRequest> = {
                          action: DISH_UPDATE,
                          parameters: {
                            user_token: token ? token : "",
                            is_enabled: !dish.is_enabled,
                            dish_id: dish.id,
                          },
                        };
                        dispatch(updateDish(request));
                      }}
                    >
                      {dish.is_enabled ? "Disabilita" : "Abilita"}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      ))}
    </Box>
  );
}

function ManageMenuComponent() {
  return (
    <DrawerAppBarHOC
      title="Lista utenti"
      content={<ManageMenuComponentBody />}
    />
  );
}

export default withAuthentication(ManageMenuComponent);
