import { useEffect } from "react";
import withAuthentication from "../base/AuthComponent";
import { BaseRequest } from "../base/BaseRequest";
import { HttpRequest } from "../base/HttpModels";
import DrawerAppBarHOC from "../home/DrawerAppBar";
import { ORDER_LIST } from "../order_history/orderHistoryActions";
import { getFullOrderList, getOrderList } from "../order_history/ordersSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { IFullOrderListRequest, IOrderEntry, OrdersState } from "../order_history/ordersTypes";
import { isMobile } from "react-device-detect";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { IDishGroup } from "../menu/menuTypes";
import { IOrderLine } from "../take_order/orderTypes";

// Register required components from Chart.js
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

function calculateTotalGain(orders: IOrderEntry[], menu: IDishGroup[]) {
  var totalGain = 0;
  try {
    orders.forEach((order) => {
      order.order_lines.forEach((orderLine) => {
        // Find the price of the dish using dish_id
        const dish = menu
          .flatMap((category) => category.dishes)
          .find((dish) => dish.id === orderLine.dish_id);
        if (dish) {
          totalGain += orderLine.quantity * dish?.price;
        }
      });
    });
  } catch (e) {
    console.error(e);
  }
  return totalGain;
}

function calculateTotalQuantities(orders: IOrderEntry[]) {
  const quantitiesMap: { [dish_id: number]: number } = {};

  try {
    orders.forEach((order) => {
      order.order_lines.forEach((orderLine) => {
        if (quantitiesMap[orderLine.dish_id]) {
          quantitiesMap[orderLine.dish_id] += orderLine.quantity;
        } else {
          quantitiesMap[orderLine.dish_id] = orderLine.quantity;
        }
      });
    });
  } catch (e) {
    console.error(e);
  }
  return quantitiesMap;
}

function calculateEarningsForTimeRange(
  orders: IOrderEntry[],
  menu: IDishGroup[],
  startTime: Date,
  endTime: Date
): number {
  let totalEarnings = 0;
  try {
    orders.forEach((order) => {
      const orderDate = new Date(order.creation_date);
      if (orderDate >= startTime && orderDate <= endTime) {
        order.order_lines.forEach((orderLine: IOrderLine) => {
          // Find the price of the dish using dish_id
          const dish = menu
            .flatMap((category) => category.dishes)
            .find((dish) => dish.id === orderLine.dish_id);

          if (dish) {
            const dishTotal = dish.price * orderLine.quantity; // Calculate total for this dish
            totalEarnings += dishTotal;
          }
        });
      }
    });
  } catch (e) {
    console.error(e);
  }
  return totalEarnings; // Return the total earnings for the time range
}

function calculateQuantitiesForTimeRange(
  orders: IOrderEntry[],
  startTime: Date,
  endTime: Date
) {
  const quantitiesMap: { [dish_id: number]: number } = {};

  try {
    orders.forEach((order) => {
      const orderDate = new Date(order.creation_date);
      if (orderDate >= startTime && orderDate <= endTime) {
        order.order_lines.forEach((orderLine) => {
          if (quantitiesMap[orderLine.dish_id]) {
            quantitiesMap[orderLine.dish_id] += orderLine.quantity;
          } else {
            quantitiesMap[orderLine.dish_id] = orderLine.quantity;
          }
        });
      }
    });
  } catch (e) {
    console.error(e);
  }

  return quantitiesMap;
}

function StatsComponentBody() {
  const orders = useAppSelector((state) => state.ordersState.fullOrders);
  const menu = useAppSelector((state) => state.menuState.menu);
  const token = useAppSelector((state) => state.loginState.token);
  const dispatch = useAppDispatch();

  const total = calculateTotalQuantities(orders);
  const gain = calculateTotalGain(orders, menu);


  useEffect(() => {
    if (token !== undefined && token !== null && token !== "") {
      const orderListRequest: HttpRequest<IFullOrderListRequest> = {
        action: ORDER_LIST,
        parameters: {
          user_token: token,
          full_list: true,
        },
      };
      dispatch(getFullOrderList(orderListRequest));
    }
  }, [dispatch, token]);

  // Define time ranges
  const timeRanges = [
    { label: "Totale 2024", startTime: null, endTime: null },
    {
      label: "Cena sabato",
      startTime: new Date("2024-09-07T18:35:00"),
      endTime: new Date("2024-09-07T23:59:59"),
    },
    {
      label: "Pranzo domenica",
      startTime: new Date("2024-09-08T11:00:00"),
      endTime: new Date("2024-09-08T14:00:00"),
    },
    {
      label: "Cena domenica)",
      startTime: new Date("2024-09-08T18:30:00"),
      endTime: new Date("2024-09-08T23:59:59"),
    },
    {
      label: "Pranzo lunedì",
      startTime: new Date("2024-09-09T11:00:00"),
      endTime: new Date("2024-09-09T14:00:00"),
    },
    {
      label: "Cena lunedì",
      startTime: new Date("2024-09-09T18:30:00"),
      endTime: new Date("2024-09-09T23:59:59"),
    },
  ];

  // Generate chart data for each time range
  const generateChartData = (quantitiesMap: { [dish_id: number]: number }) => {
    const sortedEntries = Object.entries(quantitiesMap).sort(
      ([, quantityA], [, quantityB]) => quantityB - quantityA
    );

    const dishNames = sortedEntries.map(([dish_id]) => {
      return (
        menu
          .flatMap((category) => category.dishes)
          .filter((dish) => dish.id + "" === dish_id)[0]?.name ?? "Unknown"
      );
    });

    const quantities = sortedEntries.map(([, quantity]) => quantity);

    return {
      labels: dishNames,
      datasets: [
        {
          label: "Quantities Ordered",
          data: quantities,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  // Chart.js options
  const options = {
    responsive: true,
    indexAxis: "y" as const, // Horizontal bar chart
    plugins: {
      title: {
        display: true,
        text: "Dish Quantities",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Box
      key={"menu_root"}
      sx={{
        width: "100%",
        paddingLeft: isMobile ? "0%" : "25%",
        paddingRight: isMobile ? "0%" : "25%",
        display: "block",
        alignItems: "left",
      }}
    >
      {/* Dynamically render charts for each time range */}
      {timeRanges.map(({ label, startTime, endTime }) => {
        const quantities =
          startTime && endTime
            ? calculateQuantitiesForTimeRange(orders, startTime, endTime)
            : total;

        const data = generateChartData(quantities);

        return (
          <Card
            variant="elevation"
            sx={{ width: "100%", marginBottom: "20px" }}
            key={label}
          >
            <CardContent>
              <Bar
                data={data}
                options={{
                  ...options,
                  plugins: { title: { display: true, text: label } },
                }}
              />
            </CardContent>
            <Typography variant="h6" align="center">
              {startTime && endTime
                ? calculateEarningsForTimeRange(
                    orders,
                    menu,
                    startTime,
                    endTime
                  )
                : gain}{" "}
              €
            </Typography>
          </Card>
        );
      })}
    </Box>
  );
}

function StatsComponent() {
  return (
    <DrawerAppBarHOC title="Statistiche" content={<StatsComponentBody />} />
  );
}

export default withAuthentication(StatsComponent);
