export const CAMERIERE = "Cameriere";
export const CASSIERE = "Cassiere";
export const AMMINISTRATORE = "Amministratore";

export const intFromRole = (role: string | null) => {
  switch (role) {
    case CAMERIERE:
      return 3;
    case CASSIERE:
      return 2;
    case AMMINISTRATORE:
      return 1;
    default:
      return -1;
  }
};

export const roleFromInt = (role: number | null) => {
  switch (role) {
    case 3:
      return CAMERIERE;
    case 2:
      return CASSIERE;
    case 1:
      return AMMINISTRATORE;
    default:
      return "Sconosciuto";
  }
};
