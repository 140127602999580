import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../redux/store";
import { IChangeOrderStatusRequest, IFullOrderListRequest, IOrderEntry } from "./ordersTypes";
import { initialState } from "./ordersInitialState";
import axios from "axios";
import { BaseRequest } from "../base/BaseRequest";
import { REQUEST_URL } from "../base/Constants";
import { HttpRequest } from "../base/HttpModels";
import { ORDER_CHANGE_STATUS, ORDER_LIST, ORDER_LIST_FULL } from "./orderHistoryActions";
import { setMessage } from "../message/messageSlice";
import { logMessage } from "../utils/logger";

export const changeOrderStatus = createAsyncThunk(
  ORDER_CHANGE_STATUS,
  async (request: HttpRequest<IChangeOrderStatusRequest>, dispatch) => {
    const orderListRequest: HttpRequest<BaseRequest> = {
      action: ORDER_LIST,
      parameters: {
        user_token: request.parameters.user_token,
      },
    };
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[changeOrderStatus] " + response);
      if (response.data.errors.length > 0) {
        dispatch.dispatch(
          setMessage(
            "Errore durante il cambio di stato dell'ordine\n" +
              response.data.errors[0]
          )
        );
        dispatch.dispatch(getOrderList(orderListRequest));
        return null;
      }
      if (request.parameters.order_status_id === 3) {
        dispatch.dispatch(OpenOrderDetail({ id: request.parameters.order_id }));
      }
      dispatch.dispatch(getOrderList(orderListRequest));
      return response.data.results[0] as string;
    } catch (error) {
      logMessage("[changeOrderStatus] " + error);
      dispatch.dispatch(
        setMessage("Errore durante il cambio di stato dell'ordine\n" + error)
      );
      dispatch.dispatch(getOrderList(orderListRequest));
      return null;
    }
  }
);

export const getOrderList = createAsyncThunk(
  ORDER_LIST,
  async (request: HttpRequest<BaseRequest>, dispatch) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[getOrderList] " + response);
      if (response.data.errors.length > 0) {
        dispatch.dispatch(
          setMessage(
            "Errore durante il recupero degli ordini\n" +
              response.data.errors[0]
          )
        );
        return null;
      }
      return response.data.results as Array<IOrderEntry>;
    } catch (error) {
      logMessage("[getOrderList] " + error);
      dispatch.dispatch(
        setMessage("Errore durante il recupero degli ordini\n" + error)
      );
      return null;
    }
  }
);

export const getFullOrderList = createAsyncThunk(
  ORDER_LIST_FULL,
  async (request: HttpRequest<IFullOrderListRequest>, dispatch) => {
    try {
      const response = await axios.post(REQUEST_URL, request);
      logMessage("[getOrderList] " + response);
      if (response.data.errors.length > 0) {
        dispatch.dispatch(
          setMessage(
            "Errore durante il recupero degli ordini intero\n" +
              response.data.errors[0]
          )
        );
        return null;
      }
      return response.data.results as Array<IOrderEntry>;
    } catch (error) {
      logMessage("[getOrderList] " + error);
      dispatch.dispatch(
        setMessage("Errore durante il recupero degli ordini intero\n" + error)
      );
      return null;
    }
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    OpenOrderDetail: (state, action) => {
      state.orderDetail = action.payload;
    },
    setOnlyToBePrinted: (state, action) => {
      state.onlyToBePrinted = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderList.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.orders = action.payload.sort((a, b) => {
            return new Date(a.creation_date) < new Date(b.creation_date)
              ? 1
              : -1;
          });
        } else {
          state.error = "Errore durante il recupero degli ordini";
        }
        state.loading = false;
      })
      .addCase(getOrderList.rejected, (state, action) => {
        state.error = "Richiesta rifiutata durante il recupero degli ordini";
        state.loading = false;
      })
      .addCase(changeOrderStatus.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(changeOrderStatus.fulfilled, (state, action) => {
        if (action.payload === null) {
          state.error = "Errore durante il cambio di stato dell'ordine";
        }
      })
      .addCase(changeOrderStatus.rejected, (state, action) => {
        state.error =
          "Richiesta rifiutata durante il cambio di stato dell'ordine";
      })
      .addCase(getFullOrderList.pending, (state, action) => {
        state.error = undefined;
        state.loading = true;
      })
      .addCase(getFullOrderList.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.fullOrders = action.payload.sort((a, b) => {
            return new Date(a.creation_date) < new Date(b.creation_date)
              ? 1
              : -1;
          });
        } else {
          state.error = "Errore durante il recupero degli ordini";
        }
        state.loading = false;
      })
      .addCase(getFullOrderList.rejected, (state, action) => {
        state.error = "Richiesta rifiutata durante il recupero degli ordini";
        state.loading = false;
      });
  },
});

export const { OpenOrderDetail, setOnlyToBePrinted } = ordersSlice.actions;

export const selectOrders = (state: RootState) => state.ordersState;

export default ordersSlice.reducer;
